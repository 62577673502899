import React, { Fragment, useEffect, useRef } from 'react';
import { BusClientOperation } from '../rmb/rmb-lib';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { isAuthenticated, getUserFullName } from '../auth/keycloak';
import { rmbStatus } from '../util/util';
import NoSleep from 'nosleep.js';
import { ModalInputWrapper, openInputField, closeInputField } from '../modal/ModalInput';
import { VERSION } from '../util/VersionNumber';
import ExitIcon from '../assets/icons/CloseIcon.png';

const GamePage = ({ keycloak, rmbClient }) => {
    const initializedRef = useRef(false);
    const unityContainerRef = useRef(null);
    const unityCanvasRef = useRef(null);
    let wakeLock = null;

    const {
        unityProvider,
        loadingProgression,
        isLoaded,
        sendMessage,
        addEventListener,
        removeEventListener,
        requestFullscreen,
    } = useUnityContext({
        loaderUrl: 'unity_build/jeopardy-bar-league.loader.js',
        dataUrl: 'unity_build/jeopardy-bar-league.data.unityweb',
        frameworkUrl: 'unity_build/jeopardy-bar-league.framework.js.unityweb',
        codeUrl: 'unity_build/jeopardy-bar-league.wasm.unityweb',
        streamingAssetsUrl: 'StreamingAssets',
        print: function (message) {
            console.log(message); // Suppress alert and log messages instead
        },
        printErr: function (error) {
            console.error(error); // Log errors to the console instead of triggering an alert
        },
    });

    const onUnityLogOut = (json) => {
        console.log(`[onUnityLogOut] got logout request with the json: ${json}`);
        if (isAuthenticated(keycloak)) {
            keycloak.logout({ redirectUri: window.location.origin });
            localStorage.clear();
        } else if (localStorage.getItem('isGuest') === 'true') {
            localStorage.clear();
            console.log(`[onUnityLogOut] Deleted all guest data, reloading the website`);
            window.location.reload();
        } else {
            console.log(`[onUnityLogOut] failed to log out as user wasnt signed in`);
        }
    };

    const onSessionClosed = (url) => {
        if (isLoaded === true) {
            console.log(`[onSessionClosed] recieved connection closed`);
            sendMessage('UnityToWeb', 'RMBSessionClosed', url);
        } else {
            console.error(
                'This game session has ended. You may leave this game from the menu whenever ready.',
            );
        }
    };

    const onUnityReceivedMsg = (json) => {
        console.log('[GamePage] Received data:', json);
        if (isLoaded === true) {
            sendMessage('UnityToWeb', 'ProcessNetworkMsg', json); // Pass to Unity
        } else {
            console.error(`Unity is not yet loaded[${isLoaded}], ignoring msg: ${json}`);
            // Hitting this shouldnt be possible as unity must load before attaching
        }
    };

    const onUnitySendMsg = (json) => {
        console.log(`[handleUnitySendMsg] got the json: ${json}`);
        const data = JSON.parse(json);
        rmbClient.sendData(data);

        //Enable no sleep
        if (data[3100] == undefined) {
            var noSleep = new NoSleep();
            try {
                noSleep.enable();
            } catch (error) {
                console.error('Failed to enable NoSleep:', error);
            }
            console.log('[GamePage] Enabled do not sleep');
        }
    };

    const onUnitySetOpconStatus = (json) => {
        var value = JSON.parse(json);
        console.log(`[UnityOnToggleOpcon] unity requested to toggle opcon with value: ${value}`);
        rmbClient.setOpconStatus(value);
    };

    const onRMBConnectionChanged = (connectionStatus) => {
        console.log('[UnityGamePage.onRMBConnectionChanged] RMB Status Changed:', connectionStatus);
        if (isLoaded === true) {
            sendMessage('UnityToWeb', 'NetworkStatusChanged', connectionStatus);
        } else {
            console.error(
                `Unity is not yet loaded[${isLoaded}], failed to pass RMB Status Changed: ${connectionStatus}`,
            );
        }
    };

    const onConnected = (connectionId) => {
        console.log(`[UnityGamePage.onConnected] Connected with connection ID: ${connectionId}`);
        onRMBConnectionChanged(rmbStatus.Connected);
    };

    const onError = (url) => {
        console.log(`[UnityGamePage.onError] rmb error: ${url}`);
        onRMBConnectionChanged(rmbStatus.Disconnected);
    };

    const connectToSession = async (joinCode, nickname) => {
        try {
            onRMBConnectionChanged(rmbStatus.Connecting);
            await rmbClient.connect(joinCode, nickname);
        } catch (error) {
            console.error(`got the error: ${error}`);
            rmbClient.busClient.updateFailedAttempts('[rmb-client] Failed to post bus');
            onRMBConnectionChanged(rmbStatus.Disconnected);
        }
    };

    const onUnityLeaveGame = (json) => {
        // json should be in for the format of = { "code": "theJoinCode", "nickname": "thePlayersName"}
        console.log(`[onUnityLeaveGame] unity request to leave the game with data: ${json}`);
        rmbClient.flushCachedData();

        // Remove query parameters from the URL
        const baseUrl = window.location.origin + window.location.pathname;
        console.log('baseUrl:', baseUrl);
        window.history.replaceState({}, document.title, baseUrl);

        // Reload the page
        window.location.reload();
    };

    const onUnityCopyToClipboard = (text) => {
        console.log(
            `[UnityCopyToClipboard] unity request to CopyToClipboard with the text: ${text}`,
        );
        navigator.clipboard
            .writeText(text)
            .then(() => {
                console.log(`Copied text to clipboard: ${text}`);
                alert(`Copied text to clipboard: ${text}`);
            })
            .catch((error) => {
                console.error(`Could not copy text: ${error}`);
            });
    };

    const onUnityVibrate = (json) => {
        var value = JSON.parse(json);
        console.log(`[onUnityVibrate] unity request to vibrate with value: ${value}`);
        if (navigator.vibrate) {
            navigator.vibrate(value);
        }
    };

    const onUnityVibratePattern = (json) => {
        // json should be in for the format of = { "patternArray": int[], "length": int}
        var value = JSON.parse(json);
        var patternArray = value?.patternArray;
        var length = value?.length;
        console.log(
            `[onUnityVibrate] unity request to vibrate pattern with patternArray: ${patternArray}, with lenght: ${length}`,
        );

        if (navigator.vibrate) {
            var pattern = Array.from(
                { length: length },
                (v, i) => module.HEAP32[patternArray / 4 + i],
            );
            navigator.vibrate(pattern);
        }
    };

    const onUnityRequestPlayerInfo = (json) => {
        const token = keycloak !== undefined ? keycloak.token : 'guestToken';
        const baseUrl = window.location.origin + window.location.pathname;
        const playerInfo = {
            id: rmbClient.getPlayerID(),
            deviceID: rmbClient.getDeviceID(),
            isGuest: rmbClient.isGuest(),
            keycloack_token: token,
            autoplay: localStorage.getItem('UseAutoPlay') === 'true',
            filterID: localStorage.getItem('filterID'),
            version: VERSION,
            searchParams: localStorage.getItem('savedAppSearchParams'),
            url: baseUrl,

            // Need nickname for loadtesters
            nickname: rmbClient.getNickname(),

            // Dont preset name, let unity handle this
            // fullName: getUserFullName(keycloak),
            // teamname: rmbClient.getTeamName(),
            // firstname: rmbClient.getFirstName(),
            // lastname: rmbClient.getLastName(),
        };
        // Pass player info to Unity
        sendMessage('UnityToWeb', 'ProcessPlayerInfo', JSON.stringify(playerInfo));
    };

    const onUnitySubmitJoinCode = (json) => {
        // json should be in for the format of = { "code": "theJoinCode", "nickname": "thePlayersName"}
        const data = JSON.parse(json);
        console.log(
            `[onUnitySubmitJoinCode] unity submitted the joincode data: ${JSON.stringify(data)}`,
        );
        connectToSession(data.code, data.nickname);
    };

    const onUnityOpenInputField = (json) => {
        const data = JSON.parse(json);
        console.log(
            `[onUnityOpenInputField] unity requested the input field with the data: ${JSON.stringify(data)}`,
        );
        openInputField(
            data.title,
            data.description,
            data.prefix,
            data.value,
            UnityOnInputFieldComplete,
        );
    };

    const onUnityCloseInputField = (json) => {
        console.log(`[onUnityCloseInputField] unity requested to close the input field: ${json}`);
        closeInputField();
    };

    const UnityOnInputFieldComplete = (value) => {
        console.log('[UnityOnInputFieldComplete] Input value:', value);
        sendMessage('UnityToWeb', 'OnInputFieldComplete', value);
    };

    const onUnityToggleFullscreen = (json) => {
        var value = JSON.parse(json);
        console.log(
            `[onUnityToggleFullscreen] unity requested to toggle fullscreen with value: ${value}`,
        );
        //requestFullscreen(value);

        const element = document.documentElement;

        if (value) {
            // Request fullscreen
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                // Firefox
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                // Chrome, Safari and Opera
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                // IE/Edge
                element.msRequestFullscreen();
            }
        } else {
            // Exit fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                // IE/Edge
                document.msExitFullscreen();
            }
        }
    };

    const handleModalInputActive = () => {
        console.log('handleModalInputActive');
        if (unityCanvasRef.current) {
            unityCanvasRef.current.blur();
        }
    };

    const handleModalInputClosed = () => {
        console.log('handleModalInputClosed');
        if (unityCanvasRef.current) {
            unityCanvasRef.current.focus();
        }
    };

    useEffect(() => {
        window.addEventListener('onModalInputActive', handleModalInputActive);
        window.addEventListener('onModalInputClosed', handleModalInputClosed);

        return () => {
            window.removeEventListener('onModalInputActive', handleModalInputActive);
            window.removeEventListener('onModalInputClosed', handleModalInputClosed);
        };
    }, []);

    const requestWakeLock = async () => {
        try {
            if (navigator && navigator.wakeLock && document.visibilityState === 'visible') {
                wakeLock = await navigator.wakeLock.request('screen');
                console.log('[requestWakeLock] Wake Lock activated');
            } else {
                console.log('[requestWakeLock] Wake Lock not supported or page is not visible');
            }
        } catch (error) {
            console.log('[requestWakeLock] Failed to activate Wake Lock:', error);
        }
    };

    const releaseWakeLock = async () => {
        try {
            if (wakeLock !== null) {
                await wakeLock.release();
                wakeLock = null;
                console.log('Wake Lock released');
            }
        } catch (error) {
            console.log('[releaseWakeLock] Failed to release Wake Lock:', error);
        }
    };

    useEffect(() => {
        requestWakeLock();

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                requestWakeLock();
            } else {
                releaseWakeLock();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            releaseWakeLock();
        };
    }, []);

    useEffect(() => {
        console.log(`[GamePage] isLoaded has changed: ${isLoaded}`);
        if (isLoaded) {
            console.log('[GamePage] Unity has completed loading');
            if (!initializedRef.current) {
                // Ensure that initialization occurs only once
                console.log('[GamePage.UnityAttachment] Attempting to attach rmbclient to unity');

                // Setup Rmb Client
                console.log(
                    `[GamePage.UnityAttachment] Using up rmbClient with the config: ${JSON.stringify(rmbClient)}`,
                );
                rmbClient.attachListener(BusClientOperation.Error, onError);
                rmbClient.attachListener(BusClientOperation.Connected, onConnected);
                rmbClient.attachListener(BusClientOperation.Data, onUnityReceivedMsg);
                rmbClient.attachListener(BusClientOperation.Closed, onSessionClosed);

                // Attach Unity to Javscript Event Listeners
                addEventListener('UnityLogOut', onUnityLogOut);
                addEventListener('UnitySendNetworkMsg', onUnitySendMsg);
                addEventListener('UnitySubmitJoinCode', onUnitySubmitJoinCode);
                addEventListener('UnityRequestPlayerInfo', onUnityRequestPlayerInfo);
                addEventListener('UnityLeaveGame', onUnityLeaveGame);
                addEventListener('UnityCopyToClipboard', onUnityCopyToClipboard);
                addEventListener('UnityVibrate', onUnityVibrate);
                addEventListener('UnityVibratePattern', onUnityVibratePattern);
                addEventListener('UnityOpenInputField', onUnityOpenInputField);
                addEventListener('UnityCloseInputField', onUnityCloseInputField);
                addEventListener('UnityToggleFullscreen', onUnityToggleFullscreen);
                addEventListener('UnitySetOpconStatus', onUnitySetOpconStatus);

                console.log('[GamePage.UnityAttachment] Finished attaching rmbclient to unity');

                // Finished setup
                initializedRef.current = true;
                console.log('[GamePage.UnityAttachment] completed unity setup');

                // Pass playerInfo
                onUnityRequestPlayerInfo('');
            }
        }

        return () => {
            console.log(`[GamePage.UnityAttachment] exiting with ${initializedRef.current}`);
            if (initializedRef.current) {
                // Put all clean up under logic here (if needed)
                removeEventListener('UnityLogOut', onUnityLogOut);
                removeEventListener('UnitySendNetworkMsg', onUnitySendMsg);
                removeEventListener('UnitySubmitJoinCode', onUnitySubmitJoinCode);
                removeEventListener('UnityRequestPlayerInfo', onUnityRequestPlayerInfo);
                removeEventListener('UnityLeaveGame', onUnityLeaveGame);
                removeEventListener('UnityCopyToClipboard', onUnityCopyToClipboard);
                removeEventListener('UnityVibrate', onUnityVibrate);
                removeEventListener('UnityVibratePattern', onUnityVibratePattern);
                removeEventListener('UnityOpenInputField', onUnityOpenInputField);
                removeEventListener('UnityCloseInputField', onUnityCloseInputField);
                removeEventListener('UnityToggleFullscreen', onUnityToggleFullscreen);
                removeEventListener('UnitySetOpconStatus', onUnitySetOpconStatus);
                console.log(
                    '[GamePage.UnityAttachment] Cleared all attachments from rmbclient to unity',
                );
                initializedRef.current = false;

                // Don't think this is needed but keeping a ref just incase
                rmbClient.pageClosed();
                console.log('[GamePage.UnityAttachment] rmbClient triggered pageClosed');
            }
        };
    }, [isLoaded]);

    return (
        <Fragment>
            {!isLoaded && (
                <div>
                    <div className="exit-icon-container" onClick={() => onUnityLogOut()}>
                        <img src={ExitIcon} alt="Exit" />
                    </div>
                    <div className="floating-element">
                        <div className="loading-progress">
                            <p>{Math.round(loadingProgression * 100)}%</p>
                        </div>
                        <p>Loading Game</p>
                    </div>
                </div>
            )}

            <div className="unity-container" ref={unityContainerRef}>
                <Unity
                    unityProvider={unityProvider}
                    className="unity-content"
                    ref={unityCanvasRef}
                    style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
                />
                <ModalInputWrapper></ModalInputWrapper>
            </div>
        </Fragment>
    );
};
export default GamePage;
