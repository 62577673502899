
const polynomialBytes = new Uint8Array([32, 131, 184, 237]);

module.exports = {
    SetLogger(info, warn, err) {
        this.logInfo = info;
        this.logWarn = warn;
        this.logErr = err;
    },

    readByte(view, ind) {
        const val = view.getUint8(ind);
        return { val, ind: ind + 1 };
    },

    writeByte(uint8Val, view, ind) {
        view.setUint8(ind, uint8Val);
        return ind + 1;
    },

    readString(view, ind, readSize) {
        let myInd = ind;
        let length = 0;
        if (readSize) {
            length = view.getInt32(myInd, true);
            myInd += 4;
        } else {
            length = view.byteLength - myInd;
        }

        let str = length >= 0 ? '' : undefined;

        if (length > 0) {
            const dest = new Uint8Array(length);
            for (let i = 0; i < length; i += 1, myInd += 1) {
                dest[i] = view.getUint8(myInd);
            }
            str = String.fromCharCode(...dest);
        }
        return { val: str, myInd };
    },

    writeString(str, view, ind, writeSize) {
        let myInd = ind;
        if (writeSize) {
            view.setInt32(myInd, str.length, true);
            myInd += 4;
        }
        for (let i = 0, strLen = str.length; i < strLen; i += 1, myInd += 1) {
            view.setUint8(myInd, str.charCodeAt(i));
        }

        return myInd;
    },

    packArrayBuffer(buffer) {
        this.logInfo(`[BusBytes] buffer size to pack = ${buffer.byteLength}`);
        const bytes = new Uint8Array(buffer);
        const res = this.packBytes(bytes);
        return res;
    },

    packBytes(bytes) {
        this.logInfo(`[BusBytes] bytes size to pack = ${bytes.length}`);
        const bytesUsedForSize = 2;
        const lenBytesCount = 4;
        const dataSize = 1 // protocol byte should be 0
                + bytesUsedForSize // two bytes for the number of bytes used for to encode the size
                + lenBytesCount // bytes count for size
                + polynomialBytes.length // upper border
                + bytes.length // package size
                + polynomialBytes.length; // lower border

        const buffer = new ArrayBuffer(dataSize);
        const dataView = new DataView(buffer);
        let ind = 0;

        dataView.setUint8(ind, 0);
        ind += 1;

        dataView.setUint16(ind, lenBytesCount, true);
        ind += 2;

        const payloadSize = bytes.length + (2 * polynomialBytes.length);
        this.logInfo(`[BusBytes] This is the size = ${payloadSize}`);
        dataView.setInt32(ind, payloadSize, true);
        ind += 4;

        for (let i = 0; i < polynomialBytes.length; i += 1, ind += 1) {
            dataView.setUint8(ind, polynomialBytes[i]);
        }
        for (let i = 0; i < bytes.length; i += 1, ind += 1) {
            dataView.setUint8(ind, bytes[i]);
        }
        for (let i = 0; i < polynomialBytes.length; i += 1, ind += 1) {
            dataView.setUint8(ind, polynomialBytes[i]);
        }

        return new Uint8Array(buffer);
    },

    unpack(data) {
        let dataView;
        if (typeof data === 'string' || data instanceof String) {
            this.logInfo('[BusBytes] Received string');
            const buffer = new ArrayBuffer(data.length);
            dataView = new DataView(buffer);

            for (let i = 0; i < data.length; i += 1) {
                const b = data.charCodeAt(i);
                dataView.setInt8(i, b);
            }
        } else if (data instanceof ArrayBuffer) {
            dataView = new DataView(data);
        } else {
            this.logInfo('[BusBytes] Received Invalid data');
        }

        let ind = 0;
        const protocol = dataView.getUint8(ind);
        ind += 1;

        if (protocol === 0) {
            const usedBytesForSize = dataView.getUint16(ind, true);
            ind += 2;

            let size = 0;

            if (usedBytesForSize === 4) {
                size = dataView.getUint32(ind, true);
            } else if (usedBytesForSize === 2) {
                size = dataView.getUint16(ind, true);
            } else {
                size = dataView.getUint8(ind);
            }

            ind += usedBytesForSize;

            if (size > 0) {
                const remainingBytesLength = dataView.buffer.byteLength - ind;
                const padSize = polynomialBytes.byteLength * 2;
                if (size > padSize && remainingBytesLength >= size) {
                    const poly1 = ind;
                    ind += polynomialBytes.byteLength;

                    const payloadSize = (size - padSize);
                    const payload = new DataView(dataView.buffer,
                        dataView.byteOffset + ind,
                        payloadSize);

                    const poly2 = ind + payloadSize;

                    for (let i = poly1, j = poly2, k = 0; k < polynomialBytes.byteLength;
                        i += 1, j += 1, k += 1) {
                        const check1 = dataView.getUint8(i) !== dataView.getUint8(j);
                        const check2 = dataView.getUint8(i) !== polynomialBytes[k];
                        if (check1 || check2) {
                            this.logInfo(`[BusBytes] ${usedBytesForSize} + ${ind} + ${i} + ${j} + ${k} + ${poly1} + ${poly2}`);
                            throw new Error('Malformed payload received.');
                        }
                    }
                    ind += (payloadSize + polynomialBytes.byteLength);
                    this.logInfo(`[BusBytes] Returning proper payload... ${payload.byteLength}`);
                    return payload;
                }

                throw new Error('incompleted message received.');
            } else {
                const arr = new Uint8Array(dataView.buffer);
                this.logInfo(`[BusBytes] ${ind}`);
                this.logInfo(`[BusBytes] ${usedBytesForSize}`);
                this.logInfo(`[BusBytes] ${arr}`);
                this.logInfo('[BusBytes] Received empty message');
            }
        }
        this.logInfo('[BusBytes] Returning undefined...');
        return undefined;
    },
};

module.exports.polynomialBytes = polynomialBytes;