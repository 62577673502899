import { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectPage = () => {
    console.log(`Load RedirectPage`);

    // TODO: move to a generic class for processing & updating
    const PageDataContext = createContext({
        isMostRecentStored: false,
        isLoadFromMostRecent: false,
        isLoadFromCache: false,
        setItem: () => {},
        getItem: () => {},
        isLoading: false,
        mostRecentPageData: { pathname: '', data: {} },
    });
    const { isLoading, isMostRecentStored, mostRecentPageData } = useContext(PageDataContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading) {
            if (isMostRecentStored) {
                navigate(mostRecentPageData.pathname, {
                    state: { loadMostRecentPageData: 'true' },
                    replace: true,
                });
            } else {
                navigate('/', { replace: true });
            }
        } else {
            console.log('[RedirectPage] is loading');
        }   
    }, [isLoading]);
};
