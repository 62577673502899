export const DeviceID = {
    getId: function () {
        const storedDeviceId = this.getStoredDeviceId();

        if (storedDeviceId) {
            return storedDeviceId;
        } else {
            const newDeviceId = this.generateGuid();
            this.setDeviceId(newDeviceId);
            return newDeviceId;
        }
    },

    generateGuid: function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    },

    setDeviceId: function (deviceId) {
        // Use localStorage or cookies to store the device ID
        // You may adjust this part based on your specific requirements
        if (localStorage) {
            localStorage.setItem('deviceId', deviceId);
        } else {
            // Fallback to cookies if localStorage is not available
            this.setCookie('deviceId', deviceId, 365); // 365 days expiration, adjust as needed
        }
    },

    getStoredDeviceId: function () {
        // Retrieve the device ID from localStorage or cookies
        // You may adjust this part based on your specific requirements
        if (localStorage) {
            return localStorage.getItem('deviceId');
        } else {
            // Fallback to cookies if localStorage is not available
            return this.getCookie('deviceId');
        }
    },

    getCookie: function (name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    },

    setCookie: function (name, value, days) {
        const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = `${name}=${value}; expires=${expires}; path=/`;
    }
};