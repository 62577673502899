const config = {
    keycloakAuthURL: process.env.REACT_APP_KEYCLOAK_AUTH_URL || 'http://localhost:8080/auth',
    keycloakRealm: process.env.REACT_APP_REALM || 'Users',
    keycloakClientId: process.env.REACT_APP_CLIENT_ID || 'tp-jbl',
    keycloakGuestSecret: process.env.REACT_APP_KEYCLOAK_GUEST_SECRET || '877db893-7cd4-49ae-a1a7-da71b58838fc',
    lobbyServiceURL: process.env.REACT_APP_LOBBY_SERVICE_URL || 'https://sagames-api.streamsix.com/lobby-service',
    leaderboardServiceURL: process.env.REACT_APP_LEADERBOARD_SERVICE_URL || 'https://sagames-api.streamsix.com/leaderboards',
    inventoryServiceURL: process.env.REACT_APP_INVENTORY_SERVICE_URL || 'https://sagames-api.streamsix.com/inventory-service',
    socialServiceURL: process.env.REACT_APP_SOCIAL_SERVICE_URL || 'https://sagames-api.streamsix.com/social',
    entityManagerURL: process.env.REACT_APP_ENTITY_MANAGER_URL || 'https://sagames-api.streamsix.com/entity-manager',
    analyticsServiceURL: process.env.REACT_APP_ANALYTICS_SERVICE_URL || 'https://sagames-api.streamsix.com/analytics',
};

export default config;