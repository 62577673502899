import '../App.css';

export const LoadingPage = () => {
    console.log(`Load LoadingPage`);
    return (
        <div className="loading-page">
            <p>Loading...</p>
        </div>
    );
};
