
const BusCode = {
    EndSession: -1000,
    LoadController: 100,
    LoadPage: 101,
    RequestController: 102,
    SendResources: 103,
};

const BusClientOperation = {
    Connecting: 1,
    Connected: 2,
    PlayerId: 3,
    Disconnected: 4,
    Closed: 5,
    Error: 6,
    Data: 7,
    ControllerData: 8,
    Score: 9,
    Session: 10,
    PlayerReject: 12,
};

const PeerType = {
    Room: 0,
    Player: 1,
    Actor: 2,
    OpCon: 3,
    SatellitePeer: 4,
    RemoteScoresPeer: 5,
    Chat: 6,
    Pong: 49,
};

const RoutingCmd = {
    Init: 0,
    Forward: 1,
    Data: 2,
    PlayerId: 3,
    GameRelated: 4,
    FilteredData: 5,
    Redirect: 6,
    TopScorers: 7,
    Score: 8,
    Session: 9,
    PlayerReject: 12,
    Ping: 49,
};

const RejectCode = {
    UnknownReason: 0,
    VersionTooOld: 1,
    NewDeviceSignIn: 2,
};

const ConnectionType = {
    PrivateHttps: 'private_https',
    PublicHttps: 'public_https',
    LocalHttp: 'local_http',
};

const busBytes = require('./bus-bytes');
const invalidId = -1;
const defaultTimerMillis = 10 * 1000;

class BusClient {
    constructor(playerId, deviceId, ws, info, warn, err) {
        busBytes.SetLogger(info, warn, err);
        this.Ws = ws;
        this.logInfo = info;
        this.logWarn = warn;
        this.logErr = err;
        this.url = undefined;
        this.peer = undefined;
        this.listeners = {};
        this.connected = false;
        this.hasConnected = false;
        this.connectionId = invalidId;
        this.peerType = PeerType.Player;
        this.playerId = playerId;
        this.deviceId = deviceId;
        this.pendingRedirect = undefined;
        this.lastActity = 0;
        this.timerObj = undefined;
        this.timerMillis = defaultTimerMillis;
        this.connectionType = ConnectionType.PrivateHttps;
        this.needsSuffix = true;

        // For constant reconnection logic
        this.pingAlways = true;
        this.pingPongThreshold = 3; //If x pings are missed we will assume we've lost connection
        this.missedPongCount = 0;
        this.reconnecting = false;
        this.waitingToReconnect = false;
        this.reconnectCount = 0;
    }

    connect(url, saveUrl = true) {
        const savedMS = localStorage.getItem("ms_url");
        if (savedMS != null && savedMS === url) {
            const savedSat = localStorage.getItem("sat_url");
            if (savedSat != null) { 
                this.logInfo(`[BusClient.init] Using cached satellite url ${savedSat} from ${savedMS} == ${url}`);
                url = savedSat;
                this.needsSuffix = false;
            }
        }
        

        if (saveUrl) {
            this.url = url;
        }
        this.closeConnection(this.peer);
        this.peer = this.setupConnection(url);
    }

    attemptReconnect(error)
    {
        if(this.reconnecting === false)
        {
            this.reconnectCount = 0;
            this.reconnecting = true;
            this.connected = false;
            this.stopKeepAlive();
            this.notify(BusClientOperation.Error, error);
                
            this.logInfo('[BusClient.attemptReconnect] attempting to reconnect');
            this.connect(this.url);
        }
        else if (this.waitingToReconnect === false)
        {
            this.waitingToReconnect = true;
            this.logInfo(`[BusClient.attemptReconnect] Already reconnecting will wait 3 seconds and try again. Retry attempt[${this.reconnectCount}]`);
            setTimeout(() => {
                this.reconnectCount += 1;
                this.waitingToReconnect = false;
                this.logInfo(`[BusClient.attemptReconnect] Attempting to reconect, attempt[${this.reconnectCount}]`);
                this.connect(this.url);
            }, 3000);
        }
    }

    convertToSecureWebSocket = (url) => {
        // Check if the URL starts with 'ws://'
        if (url.startsWith('ws://')) {
            const urlParts = url.split('://');
            // Check if the protocol is ws://
            if (urlParts[0] === 'ws') {
                // Get the hostname and port from the URL
                const [hostname, port] = urlParts[1].split(':');

                // Create the new URL
                const newURL = `wss://proxy.playjeopardylive.com/ws/${hostname}/${port}`;
                
                return newURL;
            } else {
                // If the protocol is not ws://, return null or handle appropriately
                return null;
            }
        } else {
            // If URL doesn't start with 'ws://', return as is
            return url;
        }
    }

    disconnect() {
        this.logInfo('[BusClient] disconnected....');
        this.closeConnection(this.peer);
    }

    closeConnection(conn) {
        if (conn !== undefined) {
            this.logInfo('[BusClient] Closing connection....');
            conn.close(1000, 'Client Initiative.');
        } else{
            this.logInfo('[BusClient] Ignored closing connection as was not yet connected');
        }
    }

    sendString(str, peerType = PeerType.Room, writeSize = false) {
        const extra = writeSize ? 4 : 0;
        const buffer = new ArrayBuffer(str.length + extra);
        const dataview = new DataView(buffer);
        busBytes.writeString(str, dataview, 0, writeSize);
        this.logInfo(`[BusClient] Writing string bytes = ${buffer.byteLength}`);
        return this.sendData(buffer, peerType);
    }

    sendObject(dataObject, peerType = PeerType.Actor) {
        // Convert the JSON string to UTF-8 encoded ArrayBuffer
        const jsonString = JSON.stringify(dataObject);
        const encoder = new TextEncoder();
        const utf8Array = encoder.encode(jsonString);
        
        // Create a new ArrayBuffer with enough space for the command byte and data
        const buffer = new ArrayBuffer(utf8Array.byteLength + 1);
        
        // Create a DataView for the new buffer
        const dataview = new DataView(buffer);
    
        // Set the command byte at the beginning of the buffer
        dataview.setUint8(0, RoutingCmd.Data);
    
        // Copy the UTF-8 encoded data to the buffer starting from index 1
        for (let i = 0; i < utf8Array.byteLength; i++) {
            dataview.setUint8(i + 1, utf8Array[i]);
        }
    
        this.logInfo(`[BusClient] Writing Data bytes = ${buffer.byteLength}`);
        
        return this.forwardTo(buffer, peerType);
    }

    sendData(arrBuffer, peerType = PeerType.Room) {
        const buffer = new ArrayBuffer(arrBuffer.byteLength + 1);
        const dataview = new DataView(buffer);
        const srcview = new DataView(arrBuffer);

        let ind = 0;
        dataview.setUint8(ind, RoutingCmd.Data);
        ind += 1;

        for (let i = 0; i < srcview.byteLength; i += 1, ind += 1) {
            dataview.setUint8(ind, srcview.getUint8(i));
        }
        this.logInfo(`[BusClient] Writing Data bytes = ${buffer.byteLength}`);
        return this.forwardTo(buffer, peerType);
    }

    forwardTo(arrBuffer, peerType) {
        const buffer = new ArrayBuffer(arrBuffer.byteLength
            + 1 // forward action
            + 1 // multiple target? true or false, always 0 (false) for webclient
            + 1 // peerType
            + 4); // 4 bytes for targets size that is always 0 for webclient

        const dataview = new DataView(buffer);
        const srcview = new DataView(arrBuffer);
        this.logInfo(`[BusClient] buffer to forward = ${buffer.byteLength}`);

        let ind = 0;
        dataview.setUint8(ind, RoutingCmd.Forward);
        ind += 1;
        dataview.setUint8(ind, 0);
        ind += 1;
        dataview.setUint8(ind, peerType);
        ind += 1;
        dataview.setInt32(ind, 0, true);
        ind += 4;

        for (let i = 0; i < srcview.byteLength; i += 1, ind += 1) {
            dataview.setUint8(ind, srcview.getUint8(i));
        }

        return this.rawSend(buffer);
    }

    rawSend(arrBuffer) {
        if (this.peer !== null && this.peer !== undefined) {
            const packed = busBytes.packArrayBuffer(arrBuffer);
            this.peer.send(packed);
            this.recordActivity();
            return packed.byteLength;
        }

        this.logInfo('[BusClient] Not connected, Ignore send request.');
        return 0;
    }

    getInitBuffer() {
        let local = this.playerId;
        if (this.needsSuffix && this.usingHttps()) {
            local = local + "###internal";
        }
        const str = JSON.stringify({
            PlayerID: local,
            DeviceID: this.deviceId,
        });
        const baseSize = 2;
        const size = baseSize + (str !== undefined ? (str.length + 4) : 0);
        const buffer = new ArrayBuffer(size);
        const dataview = new DataView(buffer);
        dataview.setUint8(0, RoutingCmd.Init);
        dataview.setUint8(1, this.peerType);

        if (size > baseSize) {
            busBytes.writeString(str, dataview, baseSize, true);
        }

        return buffer
    }

    setupConnection(url) {
        this.notify(BusClientOperation.Connecting, url);

        this.logInfo(`[BusClient] Creating websocket for ${url}`);
        const conn = new WebSocket(url);
        conn.binaryType = 'arraybuffer';
        this.logInfo(`[BusClient] Created websocket for ${url}`);

        conn.onopen = () => {

            const buffer = this.getInitBuffer();
            const sent = this.rawSend(buffer);
            this.logInfo(`[BusClient] Init bytes sent: ${sent}`);
        };

        conn.onclose = () => {
            this.logInfo('[BusClient] Closed');
            this.connected = false;
            this.peer = undefined;
            this.connectionId = invalidId;

            if (this.pendingRedirect !== undefined) {
                let redirectUrl = this.usingHttps() ? this.convertToSecureWebSocket(this.pendingRedirect) : this.pendingRedirect;
                this.logInfo(`[BusClient] initiating redirect ${redirectUrl}, with connection type: ${this.connectionType}`);
                this.pendingRedirect = undefined;
                localStorage.setItem("ms_url", this.url);
                localStorage.setItem("sat_url", redirectUrl);
                this.needsSuffix = false;
                this.connect(redirectUrl, false);
            } else {
                this.notify(BusClientOperation.Closed, this.url);
                if(this.hasConnected == true) {
                    this.attemptReconnect("ERROR S6-000: stocket became closed");
                } else{
                    this.logInfo(`[BusClient] had not yet connected, will skip attempting to reconnect`);
                }
            }
            this.stopKeepAlive();
        };

        conn.onerror = (args) => {
            this.updateFailedAttempts(args);
            this.logInfo(`[BusClient] error: ${JSON.stringify(args)}`);
            this.logInfo(`[BusClient] Failed More = ${JSON.stringify(args.message)} => ${JSON.stringify(args.error)} => ${JSON.stringify(args)}`);
            this.connected = false;
            this.notify(BusClientOperation.Error, this.url);
        };

        conn.onmessage = (evt) => {
            this.processMessage(conn, evt);
        };

        return conn;
    }

    generateUUID() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    updateFailedAttempts(args) {
        this.logInfo(`[BusClient.onSocketError] connection error : ${JSON.stringify(args)}`);
        const savedSat = localStorage.getItem("sat_url");
        
        // Retrieve the failed attempts from localStorage and convert it to an integer.
        let failedAttempts = parseInt(localStorage.getItem("connection_fails")) || 0;
    
        if (failedAttempts >= 2) {
            this.logInfo(`[BusClient.onSocketError] connection failed too many times, removing cached sat url: ${savedSat}`);
            localStorage.removeItem("sat_url");
            localStorage.removeItem("connection_fails");
        } else {
            // Increment the failed attempts and store it back in localStorage.
            failedAttempts += 1;
            localStorage.setItem("connection_fails", failedAttempts);
        }
    }

    setPlayerId() {
        const id = this.playerId; //this.generateUUID();  // dunno if we want a new id for every stocket connection?
        const size = (this.playerId != undefined) ? id.length : 0;
        const buffer = new ArrayBuffer(1 + size + 4);
        const dataview = new DataView(buffer);
        dataview.setUint8(0, RoutingCmd.PlayerId);
        busBytes.writeString(id, dataview, 1, true);

        const sent = this.rawSend(buffer);
        this.logInfo(`[rmb-lib.setPlayerId] PlayerId bytes sent: ${sent}, with id: ${id}`);
    }

    startKeepAlive(millis = 3 * 1000) {
        this.stopKeepAlive();
        this.timerMillis = millis;
        this.timerObj = setInterval(this.ping.bind(this), this.timerMillis);
        console.log("[rmb-lib] ping to keep network alive");
        return this.timerObj;
    }

    stopKeepAlive() {
        if (this.timerObj != undefined) {
            clearInterval(this.timerObj);
            this.timerObj = undefined;
        }
    }

    ping() {
        if (this.connected) {
            const rightNow = Date.now();
            if (this.pingAlways === true || (rightNow - this.lastActity) >= this.timerMillis) {
                this.logInfo(`[BusClient] pinging rmb...`);
                const buffer = new ArrayBuffer(1);
                const dataview = new DataView(buffer);
                dataview.setUint8(0, RoutingCmd.Ping);

                const sent = this.rawSend(buffer);
                this.logInfo(`[BusClient] ping bytes sent: ${sent}`);
                this.logInfo(`[BusClient] ~~~~~~ PING ~~~~~~~~`);
                this.missedPongCount += 1;
            } else {
                this.logInfo(`[BusClient] recent activity detected, don't send ping.`);
            }

            this.logInfo(`[BusClient] ping pong count: ${this.missedPongCount}`);
            if(this.missedPongCount > this.pingPongThreshold)
            {
                this.logErr(`[BusClient] Pong has not returned within threshold, we are no longer connected`);
                this.attemptReconnect("ERROR S6-001: ping/pong logic has stopped responding");
            }
        }
        else {
            this.logInfo(`[BusClient] can't ping: disconnected.`);
            this.stopKeepAlive();
        }
    }

    requestScore() {
        const buffer = new ArrayBuffer(1 + this.playerId.length);
        const dataview = new DataView(buffer);
        dataview.setUint8(0, RoutingCmd.Score);
        busBytes.writeString(this.playerId, dataview, 1, false);

        const sent = this.rawSend(buffer);
        this.logInfo(`[BusClient] Request Score bytes sent: ${sent}`);
    }

    isConnected() {
        return this.connected;
    }

    usingHttps() {
        if(localStorage.getItem("RunLocal") === 'true')
        {
            return false;
        }
        var forceHttpsLogic = true;
        return window.location.protocol === 'https:' || forceHttpsLogic === true;
    }

    setListener(code, callback) {
        if(callback === undefined)
        {
            console.warn(`[BusClient] ignored adding listener for BusClientOperation[${code}] as callback was undefined`);
            return;
        }

        if (!(code in this.listeners)) {
            this.listeners[code] = [];
        }

        // Clear for this iteration, only one listener allowed
        if (callback && typeof callback === 'function') {
            this.logInfo('[BusClient] BusPeer[setListener] - Setting listener for event', code);
            this.listeners[code] = [];
            this.listeners[code].push(callback);
        } else {
            this.logInfo('[BusClient] BusPeer[setListener] - Listener', code, 'is not a function but of type', typeof callback, '. No listener added!');
        }
    }

    clearAllListeners()
    {
        this.listeners = {};
        this.logInfo('[BusClient] cleared all listeners');
    }

    notify(code, args) {
        if (code in this.listeners) {
            const callbacks = this.listeners[code];
            for (let i = 0, l = callbacks.length; i < l; i += 1) {
                callbacks[i](args);
            }
            return true;
        }

        return false;
    }

    recordActivity() {
        this.lastActity = Date.now();
    }

    processMessage(_, evt) {
        this.recordActivity();
        this.logInfo('[BusClient] Received something...');
        const view = busBytes.unpack(evt.data);

        if (view !== undefined && view.buffer.byteLength > 0) {
            this.processPayload(view);
        } else {
            this.logInfo('[BusClient] Received empty message');
        }
    }

    processPayload(dataView) {
        let ind = 0;
        const command = dataView.getUint8(ind);
        this.logInfo(`[BusClient] Process Command: ${command}`);
        ind += 1;
        let len = dataView.byteLength - ind;

        switch (command) {
            case RoutingCmd.Redirect: {
                const chars = new Uint8Array(len);
                for (let i = 0; i < len; i += 1, ind += 1) {
                    chars[i] = dataView.getUint8(ind);
                }

                let url = String.fromCharCode.apply(null, chars);
                if (!url.startsWith('ws')) {
                    url = `ws://${url}`;
                }
                
                this.logInfo(`[BusClient] got base redirect url: ${url},`);
                if (this.usingHttps()) {
                    url = this.convertToSecureWebSocket(url);
                }

                this.logInfo(`[BusClient] Redirecting to: ${url}, with connection type ${this.connectionType}`);
                this.pendingRedirect = url;
                this.disconnect();
                break;
            }

            case RoutingCmd.Init: {
                const chars = new Uint8Array(len);
                for (let i = 0; i < len; i += 1, ind += 1) {
                    chars[i] = dataView.getUint8(ind);
                }

                const id = String.fromCharCode.apply(null, chars);
                this.logInfo(`[BusClient] raw Id: ${id} => ${dataView.byteOffset} => ${chars[0]}`);

                const connId = parseInt(id, 10);
                this.connected = true;
                this.hasConnected = true;
                this.connectionId = connId;
                
                // Reset reconnection values
                const completedReconnect = this.reconnecting;
                this.missedPongCount = 0;
                this.reconnectCount = 0;
                this.reconnecting = false;
                this.waitingToReconnect = false;
                localStorage.setItem("connection_fails", 0);
                
                this.logInfo(`[BusClient] Connected with connection Id: ${connId}`);
                this.notify(BusClientOperation.Connected, this.connectionId);

                if(completedReconnect === true)
                {
                    //TODO: add callback as we now we just reconnected mid game when we connect & this.reconnecting == true
                    this.logInfo(`[BusClient] Succesfully reconnected with connection Id: ${connId}`);
                    //this.notify(BusClientOperation.Reconnected, this.connectionId);
                }
                break;
            }

            case RoutingCmd.PlayerId: {
                this.logInfo('[BusClient] Processing playerId');
                len = dataView.getInt32(ind, true);
                ind += 4;
                this.logInfo(`[BusClient] playerId len = ${len}`);
                const chars = new Uint8Array(len);
                for (let i = 0; i < len; i += 1, ind += 1) {
                    chars[i] = dataView.getUint8(ind);
                }
                const id = String.fromCharCode.apply(null, chars);

                let session = 'undefined';

                if (dataView.byteLength > (ind + 4)) {
                    len = dataView.getInt32(ind, true);
                    ind += 4;
                    this.logInfo(`[BusClient] session len = ${len}`);
                    const sessionChars = new Uint8Array(len);
                    for (let i = 0; i < len; i += 1, ind += 1) {
                        sessionChars[i] = dataView.getUint8(ind);
                    }
                    session = String.fromCharCode.apply(null, sessionChars);
                }

                this.logInfo(`[BusClient] Player ID  ${id} (${this.playerId}) set for connID ${this.connectionId}`);
                this.notify(BusClientOperation.PlayerId, id);
                this.notify(BusClientOperation.Session, session);
                break;
            }

            case RoutingCmd.Session: {
                this.logInfo('[BusClient] Processing Session');
                len = dataView.getInt32(ind, true);
                ind += 4;
                this.logInfo(`[BusClient] session len = ${len}`);
                const chars = new Uint8Array(len);
                for (let i = 0; i < len; i += 1, ind += 1) {
                    chars[i] = dataView.getUint8(ind);
                }
                const session = String.fromCharCode.apply(null, chars);

                this.notify(BusClientOperation.Session, session);
                break;
            }

            case RoutingCmd.Data: {
                const bytes = new Uint8Array(dataView.buffer, dataView.byteOffset + ind, len);
                this.notify(BusClientOperation.Data, bytes);
                break;
            }

            case RoutingCmd.FilteredData: {
                const filter = dataView.getUint8(ind);
                ind += 1;
                const bytes = new Uint8Array(dataView.buffer, dataView.byteOffset + ind, len);
                if (filter === 1) { // controller
                    this.notify(BusClientOperation.ControllerData, bytes);
                } else {
                    this.notify(BusClientOperation.Data, bytes);
                }
                break;
            }

            case RoutingCmd.Score: {
                const bytes = new Uint8Array(dataView.buffer, dataView.byteOffset + ind, len);
                const json = String.fromCharCode(...bytes);
                this.logInfo(`[BusClient] Received score ${json}`);
                const score = JSON.parse(json);
                this.notify(BusClientOperation.Score, score);
                break;
            }

            case RoutingCmd.PlayerReject: {
                const reason = dataView.getUint8(ind);
                this.notify(BusClientOperation.PlayerReject, reason);
                break;
            }
            case RoutingCmd.Ping: {
                this.logInfo(`[BusClient] Received pong with no value`);
                this.logInfo(`[BusClient] ===== PONG ==========`);
                this.missedPongCount = 0;
                this.logInfo(`[BusClient] reset ping pong count: ${this.missedPongCount}`);

                this.notify(BusClientOperation.Pong, RoutingCmd.Ping);
                break;
            }

            default: {
                this.logWarn(`[BusClient] unknown command ${command}`);
                break;
            }
        }
    }
}

class ChatClient extends BusClient {
    constructor(ws, info, warn, err) {
        super("Chat-client", "Chat-Device", ws, info, warn, err)
        this.peerType = PeerType.Chat;
    }

    getInitBuffer() {
        const size = 2;
        const buffer = new ArrayBuffer(size);
        const dataview = new DataView(buffer);
        dataview.setUint8(0, RoutingCmd.Init);
        dataview.setUint8(1, this.peerType);
        return buffer
    }

    sendString(str, _peerType = PeerType.Room, _writeSize = false) {
        this.logInfo(`[ChatClient] redirecting sendString to sendChatString: ${str}`);
        return this.sendChatString(str);
    }

    sendData(obj, _peerType = PeerType.Room) {
        this.logInfo(`[ChatClient] redirecting sendData to sendChatObject`);
        return this.sendChatObject(obj);
    }

    sendChatObject(obj) {
        const str = JSON.stringify(obj);
        return this.sendChatString(str)
    }

    sendChatString(msg) {
        const str = msg;
        const buffer = new ArrayBuffer(str.length);
        const dataview = new DataView(buffer);
        busBytes.writeString(str, dataview, 0, false);
        this.logInfo(`[ChatClient] Writing chat bytes = ${buffer.byteLength}`);
        return this.forwardTo(buffer, PeerType.Room);
    }
}

module.exports = {
    BusClient,
    BusClientOperation,
    BusCode,
    PeerType,
    RoutingCmd,
    RejectCode,
    ConnectionType,
    ChatClient,
};