import './App.css';

import React, { useEffect, useState } from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    RouterProvider,
    Route,
    Routes,
} from 'react-router-dom';
import { isAuthenticated } from './auth/keycloak';
import { useKeycloak } from '@react-keycloak/web';
import { RedirectPage } from './pages/RedirectPage';
import { WelcomePage } from './pages/WelcomePage';
import { LoadingPage } from './pages/LoadingPage';
import GamePage from './pages/GamePage';
import {
    generateGuestUID,
    checkGuestExpiration,
    isGuest,
    SignInAsGuest,
} from './util/guestDataHandler';

function App({ rmbClient }) {
    const { keycloak, initialized } = useKeycloak();
    console.log(`[Main] using rmbClient with config: ${JSON.stringify(rmbClient)}`);

    checkGuestExpiration();

    const handleLoginRedirect = (source, keycloak) => {
        const sourcePathStorageKey = 'streamsix_keycloak_redir_source';
        const redirectUrl = window.location.origin;
        console.log(`[handleLoginRedirect] got the redirectUrl: ${redirectUrl}`);

        if (redirectUrl === '') {
            keycloak?.login();
        } else {
            localStorage.setItem(sourcePathStorageKey, source);
            keycloak?.login({ redirectUri: redirectUrl });
        }
    };

    // This handles the pages that are for authenticated users only
    const AuthenticatedPagesHandler = ({ keycloak }) => {
        return (
            <Routes>
                <Route element={<GamePage keycloak={keycloak} />}>
                    <Route path="/" element={<RedirectPage />} />
                    <Route path="*" element={<Navigate to="/" />} />{' '}
                </Route>
            </Routes>
        );
    };

    const UnauthenticatedPagesHandler = (props) => {
        return (
            <Routes>
                <Route element={<WelcomePage signInAsGuest={SignInAsGuest} />}>
                    <Route
                        path="*"
                        element={<></>}
                        handle={handleLoginRedirect(props.source, props.keycloak)}
                    />
                </Route>
            </Routes>
        );
    };

    const router = createBrowserRouter([
        ...createRoutesFromElements(
            initialized ? (
                <Route>
                    {isAuthenticated(keycloak) || isGuest() ? (
                        <Route>
                            <Route
                                path="/"
                                element={<GamePage keycloak={keycloak} rmbClient={rmbClient} />}
                            />
                            <Route
                                path="*"
                                element={<AuthenticatedPagesHandler keycloak={keycloak} />}
                            />
                        </Route>
                    ) : (
                        <Route>
                            <Route
                                path="/"
                                element={<WelcomePage signInAsGuest={SignInAsGuest} />}
                            />
                            <Route
                                path="*"
                                element={
                                    <UnauthenticatedPagesHandler
                                        keycloak={keycloak}
                                        source={window.location.pathname}
                                    />
                                }
                            />
                        </Route>
                    )}
                </Route>
            ) : (
                <Route path="*" element={<LoadingPage />} />
            ),
        ),
    ]);
    return <RouterProvider router={router} />;
}

export default App;
