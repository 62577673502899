// Overwritten by Env File, but good if we want to add dynamic env switching
export const GameLicenses = {
    "dev"   : "56d11073-cbd1-4d8f-b402-ef970b360c65",
    "test"  : "---------- Does not exist ----------",
    "stg"   : "6a795c3a-955f-4f71-85da-454b7f902bd8",
    "prod"  : "3ab9a496-66cb-4826-b1bf-62e8734e2e40",
};

export const rmbStatus = {
    "Disconnected"  : 'Disconnected',
    "Connecting"    : 'Connecting',
    "Connected"     : 'Connected'
};

export const saveSearchParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsDict = {};

    let size = 0;
    urlParams.forEach((value, key) => {
        paramsDict[key] = value;
        size++;
    });

    console.log(`[saveSearchParams] got the search params as dictionary:`, paramsDict);
    if (size > 0) {
        const json = JSON.stringify(paramsDict);
        console.log(`[saveSearchParams] got size[${size}] search params. Saving the value:`, json);
        localStorage.setItem('savedAppSearchParams', json);
    } else{
        console.log(`[saveSearchParams] search params was empty`);
    }
};

export const updateSearchParams = (key, value) => {
    const json = localStorage.getItem('savedAppSearchParams');
    let paramsDict = {};
    if (json != undefined && json != ""){
        console.log(`[saveSearchParams] got the search params as dictionary:`, paramsDict);
        paramsDict = JSON.parse(json);
    }

    paramsDict[key] = value;
    const updateJson = JSON.stringify(paramsDict);
    console.log(`[updateSearchParams] updated saved search Params:`, updateJson);
    localStorage.setItem('savedAppSearchParams', updateJson);
};

export const deleteSearchParams = () => {
    localStorage.removeItem('savedAppSearchParams');
    localStorage.removeItem('SavedJoinCode');
};